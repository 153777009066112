@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,800,700,600);
/*************************************************************
*						App Variables
**************************************************************/
/*************************************************************
*					  Colors Definitions
**************************************************************/
/*************************************************************
*					Common Element Variables
**************************************************************/
/* ***************************************************
*			Page Specific Element Variables
****************************************************** */
/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #4f5f6f;
  overflow-x: hidden; }

.main-wrapper {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

#ref .color-primary {
  color: #85CE36; }

#ref .chart .color-primary {
  color: #85CE36; }

#ref .chart .color-secondary {
  color: #9ed85f; }

.app {
  position: relative;
  width: 100%;
  padding-left: 240px;
  min-height: 100vh;
  margin: 0 auto;
  left: 0;
  background-color: #f0f3f6;
  -webkit-box-shadow: 0 0 3px #ccc;
          box-shadow: 0 0 3px #ccc;
  -webkit-transition: left 0.3s ease, padding-left 0.3s ease;
  transition: left 0.3s ease, padding-left 0.3s ease;
  overflow: hidden; }
  .app .content {
    padding: 95px 20px 75px 20px;
    min-height: 100vh; }
    @media (min-width: 1200px) {
      .app .content {
        padding: 105px 40px 85px 40px; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .app .content {
        padding: 100px 35px 80px 35px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .app .content {
        padding: 95px 20px 75px 20px; } }
    @media (max-width: 767.98px) {
      .app .content {
        padding: 70px 20px 70px 20px; } }
  @media (max-width: 991.98px) {
    .app {
      padding-left: 0; } }
  @media (max-width: 991.98px) {
    .app.sidebar-open {
      left: 0; } }
  .app.blank {
    background-color: #667380; }

.auth {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #667380;
  overflow-x: hidden;
  overflow-y: auto; }

.auth-container {
  width: 450px;
  min-height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%); }
  .auth-container .auth-header {
    text-align: center;
    border-bottom: 1px solid #85CE36; }
  .auth-container .auth-title {
    color: #97A4B1;
    padding: 20px;
    line-height: 30px;
    font-size: 26px;
    font-weight: 600;
    margin: 0; }
  .auth-container .auth-content {
    padding: 30px 50px;
    min-height: 260px; }
  .auth-container .forgot-btn {
    line-height: 28px; }
  .auth-container .checkbox label {
    padding: 0; }
  .auth-container .checkbox a {
    vertical-align: text-top; }
  .auth-container .checkbox span {
    color: #4f5f6f; }

@media (max-width: 767.98px) {
  .auth-container {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: inherit;
            transform: inherit;
    margin: 0;
    margin-bottom: 10px; }
    .auth-container .auth-content {
      padding: 30px 25px; } }

/*****************************************
*       Background-image: cover mixin
******************************************/
/*****************************************
*       Media Query Mixins
******************************************/
.row-sm {
  margin-left: -10px;
  margin-right: -10px; }
  .row-sm [class^='col'] {
    padding-left: 10px;
    padding-right: 10px; }

.title-block {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d7dde4; }
  .title-block::after {
    display: block;
    clear: both;
    content: ""; }
  @media (max-width: 767.98px) {
    .title-block {
      margin-bottom: 20px; } }

.subtitle-block {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #e0e5ea; }

.section {
  display: block;
  margin-bottom: 15px; }
  @media (min-width: 1200px) {
    .section {
      margin-bottom: 25px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .section {
      margin-bottom: 20px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section {
      margin-bottom: 15px; } }
  @media (max-width: 767.98px) {
    .section {
      margin-bottom: 10px; } }
  .section:last-of-type {
    margin-bottom: 0; }

.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444;
  cursor: pointer; }

.underline-animation {
  position: absolute;
  top: auto;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #85CE36;
  content: '';
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0); }

.stat-chart {
  border-radius: 50%; }

.stat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 10px; }
  .stat .value {
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500; }
  .stat .name {
    overflow: hidden;
    text-overflow: ellipsis; }
  .stat.lg .value {
    font-size: 26px;
    line-height: 28px; }
  .stat.lg .name {
    font-size: 16px; }

.list-icon [class^='col'] {
  cursor: pointer; }
  .list-icon [class^='col'] em {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    -webkit-transition: all 1s;
    transition: all 1s;
    line-height: 30px; }
  .list-icon [class^='col']:hover em {
    -webkit-transform: scale(2, 2);
            transform: scale(2, 2); }

.well {
  background-image: none;
  background-color: #fff; }

.jumbotron {
  background-image: none;
  background-color: #fff;
  padding: 15px 30px; }
  .jumbotron.jumbotron-fluid {
    padding-left: 0;
    padding-right: 0; }

.rounded {
  border-radius: 0.25rem; }

.rounded-l {
  border-radius: 0.3rem; }

.rounded-s {
  border-radius: 0.2rem; }

.jqstooltip {
  height: 25px !important;
  width: auto !important;
  border-radius: 0.2rem; }

.title {
  font-size: 1.45rem;
  font-weight: 600;
  margin-bottom: 0; }
  .title.l {
    font-size: 1.6rem; }
  .title.s {
    font-size: 1.4rem; }
  .card .title {
    font-size: 1.1rem;
    color: #4f5f6f; }

.title-description {
  margin: 0;
  font-size: 0.9rem;
  font-weight: normal;
  color: #7e8e9f; }
  .title-description.s {
    font-size: 0.8rem; }
  @media (max-width: 767.98px) {
    .title-description {
      display: none; } }

.subtitle {
  font-size: 1.2rem;
  margin: 0;
  color: #7e8e9f; }

.text-primary {
  color: #85CE36; }

.text-muted {
  color: #9ba8b5; }

pre {
  padding: 0;
  border: none;
  background: none; }

.alert {
  background-image: none; }
  .alert.alert-primary {
    color: #ffffff;
    background-color: #85CE36;
    border-color: #85CE36; }
    .alert.alert-primary hr {
      border-top-color: #78bd2e; }
    .alert.alert-primary .alert-link {
      color: #e6e6e6; }
  .alert.alert-success {
    color: #ffffff;
    background-color: #4bcf99;
    border-color: #4bcf99; }
    .alert.alert-success hr {
      border-top-color: #37ca8e; }
    .alert.alert-success .alert-link {
      color: #e6e6e6; }
  .alert.alert-info {
    color: #ffffff;
    background-color: #76D4F5;
    border-color: #76D4F5; }
    .alert.alert-info hr {
      border-top-color: #5ecdf3; }
    .alert.alert-info .alert-link {
      color: #e6e6e6; }
  .alert.alert-warning {
    color: #ffffff;
    background-color: #fe974b;
    border-color: #fe974b; }
    .alert.alert-warning hr {
      border-top-color: #fe8832; }
    .alert.alert-warning .alert-link {
      color: #e6e6e6; }
  .alert.alert-danger {
    color: #ffffff;
    background-color: #FF4444;
    border-color: #FF4444; }
    .alert.alert-danger hr {
      border-top-color: #ff2b2b; }
    .alert.alert-danger .alert-link {
      color: #e6e6e6; }
  .alert.alert-inverse {
    color: #ffffff;
    background-color: #131e26;
    border-color: #131e26; }
    .alert.alert-inverse hr {
      border-top-color: #0b1115; }
    .alert.alert-inverse .alert-link {
      color: #e6e6e6; }

.animated {
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-delay: .1s;
          animation-delay: .1s; }

.btn {
  background-image: none;
  border-radius: 0;
  margin-bottom: 5px;
  line-height: 1.2; }
  .btn, .btn:hover {
    color: #ffffff; }
  .btn.btn-primary {
    color: #212529;
    background-color: #85CE36;
    border-color: #85CE36; }
    .btn.btn-primary:hover {
      color: #fff;
      background-color: #72b22b;
      border-color: #6ba829; }
    .btn.btn-primary:focus, .btn.btn-primary.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5); }
    .btn.btn-primary.disabled, .btn.btn-primary:disabled {
      color: #212529;
      background-color: #85CE36;
      border-color: #85CE36; }
    .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #6ba829;
      border-color: #649e26; }
      .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5); }
    .btn.btn-primary, .btn.btn-primary:hover {
      color: #ffffff; }
  .btn.btn-secondary {
    color: #212529;
    background-color: #fff;
    border-color: #d7dde4; }
    .btn.btn-secondary:hover {
      color: #212529;
      background-color: #ececec;
      border-color: #b9c3cf; }
    .btn.btn-secondary:focus, .btn.btn-secondary.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5); }
    .btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
      color: #212529;
      background-color: #fff;
      border-color: #d7dde4; }
    .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-secondary.dropdown-toggle {
      color: #212529;
      background-color: #e6e6e6;
      border-color: #b1bdca; }
      .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-secondary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5); }
    .btn.btn-secondary, .btn.btn-secondary:hover {
      color: #4f5f6f; }
  .btn.btn-success {
    color: #212529;
    background-color: #4bcf99;
    border-color: #4bcf99; }
    .btn.btn-success:hover {
      color: #fff;
      background-color: #33c087;
      border-color: #31b680; }
    .btn.btn-success:focus, .btn.btn-success.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5); }
    .btn.btn-success.disabled, .btn.btn-success:disabled {
      color: #212529;
      background-color: #4bcf99;
      border-color: #4bcf99; }
    .btn.btn-success:not(:disabled):not(.disabled):active, .btn.btn-success:not(:disabled):not(.disabled).active,
    .show > .btn.btn-success.dropdown-toggle {
      color: #fff;
      background-color: #31b680;
      border-color: #2eac79; }
      .btn.btn-success:not(:disabled):not(.disabled):active:focus, .btn.btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-success.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5); }
  .btn.btn-info {
    color: #212529;
    background-color: #76D4F5;
    border-color: #76D4F5; }
    .btn.btn-info:hover {
      color: #212529;
      background-color: #52c9f2;
      border-color: #46c5f2; }
    .btn.btn-info:focus, .btn.btn-info.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5); }
    .btn.btn-info.disabled, .btn.btn-info:disabled {
      color: #212529;
      background-color: #76D4F5;
      border-color: #76D4F5; }
    .btn.btn-info:not(:disabled):not(.disabled):active, .btn.btn-info:not(:disabled):not(.disabled).active,
    .show > .btn.btn-info.dropdown-toggle {
      color: #212529;
      background-color: #46c5f2;
      border-color: #3bc1f1; }
      .btn.btn-info:not(:disabled):not(.disabled):active:focus, .btn.btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-info.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5); }
  .btn.btn-warning {
    color: #212529;
    background-color: #fe974b;
    border-color: #fe974b; }
    .btn.btn-warning:hover {
      color: #212529;
      background-color: #fe8125;
      border-color: #fe7a18; }
    .btn.btn-warning:focus, .btn.btn-warning.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5); }
    .btn.btn-warning.disabled, .btn.btn-warning:disabled {
      color: #212529;
      background-color: #fe974b;
      border-color: #fe974b; }
    .btn.btn-warning:not(:disabled):not(.disabled):active, .btn.btn-warning:not(:disabled):not(.disabled).active,
    .show > .btn.btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #fe7a18;
      border-color: #fe720c; }
      .btn.btn-warning:not(:disabled):not(.disabled):active:focus, .btn.btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-warning.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5); }
  .btn.btn-danger {
    color: #fff;
    background-color: #FF4444;
    border-color: #FF4444; }
    .btn.btn-danger:hover {
      color: #fff;
      background-color: #ff1e1e;
      border-color: #ff1111; }
    .btn.btn-danger:focus, .btn.btn-danger.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5); }
    .btn.btn-danger.disabled, .btn.btn-danger:disabled {
      color: #fff;
      background-color: #FF4444;
      border-color: #FF4444; }
    .btn.btn-danger:not(:disabled):not(.disabled):active, .btn.btn-danger:not(:disabled):not(.disabled).active,
    .show > .btn.btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #ff1111;
      border-color: #ff0404; }
      .btn.btn-danger:not(:disabled):not(.disabled):active:focus, .btn.btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-danger.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5); }
  .btn.btn-primary-outline {
    color: #85CE36;
    background-color: transparent;
    background-image: none;
    border-color: #85CE36; }
    .btn.btn-primary-outline:hover {
      color: #212529;
      background-color: #85CE36;
      border-color: #85CE36; }
    .btn.btn-primary-outline:focus, .btn.btn-primary-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5); }
    .btn.btn-primary-outline.disabled, .btn.btn-primary-outline:disabled {
      color: #85CE36;
      background-color: transparent; }
    .btn.btn-primary-outline:not(:disabled):not(.disabled):active, .btn.btn-primary-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary-outline.dropdown-toggle {
      color: #212529;
      background-color: #85CE36;
      border-color: #85CE36; }
      .btn.btn-primary-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-primary-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-primary-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(133, 206, 54, 0.5); }
  .btn.btn-secondary-outline {
    color: #d7dde4;
    background-color: transparent;
    background-image: none;
    border-color: #d7dde4; }
    .btn.btn-secondary-outline:hover {
      color: #212529;
      background-color: #d7dde4;
      border-color: #d7dde4; }
    .btn.btn-secondary-outline:focus, .btn.btn-secondary-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5); }
    .btn.btn-secondary-outline.disabled, .btn.btn-secondary-outline:disabled {
      color: #d7dde4;
      background-color: transparent; }
    .btn.btn-secondary-outline:not(:disabled):not(.disabled):active, .btn.btn-secondary-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-secondary-outline.dropdown-toggle {
      color: #212529;
      background-color: #d7dde4;
      border-color: #d7dde4; }
      .btn.btn-secondary-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-secondary-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(215, 221, 228, 0.5); }
  .btn.btn-info-outline {
    color: #76D4F5;
    background-color: transparent;
    background-image: none;
    border-color: #76D4F5; }
    .btn.btn-info-outline:hover {
      color: #212529;
      background-color: #76D4F5;
      border-color: #76D4F5; }
    .btn.btn-info-outline:focus, .btn.btn-info-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5); }
    .btn.btn-info-outline.disabled, .btn.btn-info-outline:disabled {
      color: #76D4F5;
      background-color: transparent; }
    .btn.btn-info-outline:not(:disabled):not(.disabled):active, .btn.btn-info-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-info-outline.dropdown-toggle {
      color: #212529;
      background-color: #76D4F5;
      border-color: #76D4F5; }
      .btn.btn-info-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-info-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-info-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(118, 212, 245, 0.5); }
  .btn.btn-success-outline {
    color: #4bcf99;
    background-color: transparent;
    background-image: none;
    border-color: #4bcf99; }
    .btn.btn-success-outline:hover {
      color: #212529;
      background-color: #4bcf99;
      border-color: #4bcf99; }
    .btn.btn-success-outline:focus, .btn.btn-success-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5); }
    .btn.btn-success-outline.disabled, .btn.btn-success-outline:disabled {
      color: #4bcf99;
      background-color: transparent; }
    .btn.btn-success-outline:not(:disabled):not(.disabled):active, .btn.btn-success-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-success-outline.dropdown-toggle {
      color: #212529;
      background-color: #4bcf99;
      border-color: #4bcf99; }
      .btn.btn-success-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-success-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-success-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(75, 207, 153, 0.5); }
  .btn.btn-warning-outline {
    color: #fe974b;
    background-color: transparent;
    background-image: none;
    border-color: #fe974b; }
    .btn.btn-warning-outline:hover {
      color: #212529;
      background-color: #fe974b;
      border-color: #fe974b; }
    .btn.btn-warning-outline:focus, .btn.btn-warning-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5); }
    .btn.btn-warning-outline.disabled, .btn.btn-warning-outline:disabled {
      color: #fe974b;
      background-color: transparent; }
    .btn.btn-warning-outline:not(:disabled):not(.disabled):active, .btn.btn-warning-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-warning-outline.dropdown-toggle {
      color: #212529;
      background-color: #fe974b;
      border-color: #fe974b; }
      .btn.btn-warning-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-warning-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-warning-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(254, 151, 75, 0.5); }
  .btn.btn-danger-outline {
    color: #FF4444;
    background-color: transparent;
    background-image: none;
    border-color: #FF4444; }
    .btn.btn-danger-outline:hover {
      color: #fff;
      background-color: #FF4444;
      border-color: #FF4444; }
    .btn.btn-danger-outline:focus, .btn.btn-danger-outline.focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5); }
    .btn.btn-danger-outline.disabled, .btn.btn-danger-outline:disabled {
      color: #FF4444;
      background-color: transparent; }
    .btn.btn-danger-outline:not(:disabled):not(.disabled):active, .btn.btn-danger-outline:not(:disabled):not(.disabled).active,
    .show > .btn.btn-danger-outline.dropdown-toggle {
      color: #fff;
      background-color: #FF4444;
      border-color: #FF4444; }
      .btn.btn-danger-outline:not(:disabled):not(.disabled):active:focus, .btn.btn-danger-outline:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-danger-outline.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(255, 68, 68, 0.5); }
  .btn.btn-pill-left:focus, .btn.btn-pill-right:focus, .btn.btn-oval:focus {
    outline: none;
    outline-offset: initial; }
  .btn.btn-pill-left {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px; }
  .btn.btn-pill-right {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px; }
  .btn.btn-oval {
    border-radius: 25px; }
  .btn.btn-link {
    text-decoration: none; }
  .btn strong {
    font-weight: 600; }

.btn-group .dropdown-menu > li:last-child a:hover:before {
  height: 0px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0); }

.card {
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
          box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
  margin-bottom: 10px;
  border-radius: 0;
  border: none; }
  .card .card {
    -webkit-box-shadow: none;
            box-shadow: none; }
  .card .card-header {
    background-image: none;
    background-color: #ffffff;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0;
    border-radius: 0;
    min-height: 50px;
    border: none; }
    .card .card-header::after {
      display: block;
      clear: both;
      content: ""; }
    .card .card-header.bordered {
      border-bottom: 1px solid #d7dde4; }
    .card .card-header.card-header-sm {
      min-height: 40px; }
    .card .card-header > span {
      vertical-align: middle; }
    .card .card-header .pull-right {
      margin-left: auto; }
    .card .card-header .header-block {
      padding: 0.5rem 15px; }
      @media (min-width: 1200px) {
        .card .card-header .header-block {
          padding: 0.5rem 20px; } }
      @media (max-width: 767.98px) {
        .card .card-header .header-block {
          padding: 0.5rem 10px; } }
    .card .card-header .title {
      color: #4f5f6f;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
    .card .card-header .btn {
      margin: 0; }
    .card .card-header .nav-tabs {
      border-color: transparent;
      -ms-flex-item-align: stretch;
          align-self: stretch;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      top: 1px; }
      .card .card-header .nav-tabs .nav-item {
        margin-left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
            align-self: stretch; }
        .card .card-header .nav-tabs .nav-item .nav-link {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-item-align: stretch;
              align-self: stretch;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          color: #4f5f6f;
          opacity: 0.7;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 0;
          font-size: 14px;
          border-top-width: 2px;
          border-bottom: 1px solid #d7dde4;
          text-decoration: none; }
          .card .card-header .nav-tabs .nav-item .nav-link.active {
            border-top-color: #85CE36;
            border-bottom-color: transparent;
            opacity: 1; }
            .card .card-header .nav-tabs .nav-item .nav-link.active:hover, .card .card-header .nav-tabs .nav-item .nav-link.active:focus {
              opacity: 1;
              background-color: #ffffff;
              border-color: #d7dde4 #d7dde4 transparent;
              border-top-color: #85CE36; }
          .card .card-header .nav-tabs .nav-item .nav-link:hover, .card .card-header .nav-tabs .nav-item .nav-link:focus {
            opacity: 1;
            background-color: transparent;
            border-color: transparent; }
  .card.card-default > .card-header {
    background-color: #fff;
    color: inherit; }
  .card.card-primary {
    border-color: #85CE36; }
    .card.card-primary > .card-header {
      background-color: #85CE36;
      border-color: #85CE36; }
  .card.card-success > .card-header {
    background-color: #4bcf99; }
  .card.card-info > .card-header {
    background-color: #76D4F5; }
  .card.card-warning > .card-header {
    background-color: #fe974b; }
  .card.card-danger > .card-header {
    background-color: #FF4444; }
  .card.card-inverse > .card-header {
    background-color: #131e26; }
  .card .title-block,
  .card .card-title-block {
    padding-bottom: 0;
    margin-bottom: 20px;
    border: none; }
    .card .title-block::after,
    .card .card-title-block::after {
      display: block;
      clear: both;
      content: ""; }
  .card .section {
    margin-bottom: 20px; }
  .card .example,
  .card .section.demo {
    margin-bottom: 20px; }

.card-block {
  padding: 15px; }
  .card-block .tab-content {
    padding: 0;
    border-color: transparent; }
  @media (min-width: 1200px) {
    .card-block {
      padding: 20px; } }
  @media (max-width: 767.98px) {
    .card-block {
      padding: 10px; } }

.card-footer {
  background-color: #fafafa; }

.easy-pie-chart {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #d7dde4;
  border-radius: 5px; }

.dropdown-menu {
  float: left;
  -webkit-box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
          box-shadow: 2px 3px 6px rgba(126, 142, 159, 0.1);
  border: 1px solid rgba(126, 142, 159, 0.1);
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .dropdown-menu .dropdown-item {
    display: block;
    padding: 0px 15px;
    clear: both;
    font-weight: normal;
    color: #4f5f6f;
    white-space: nowrap;
    -webkit-transition: none;
    transition: none; }
    .dropdown-menu .dropdown-item i {
      margin-right: 2px; }
    .dropdown-menu .dropdown-item:hover {
      color: #85CE36 !important;
      background: none;
      background-color: whitesmoke; }

input,
textarea {
  outline: none; }

.form-group .row {
  margin-left: -10px;
  margin-right: -10px; }
  .form-group .row [class^='col'] {
    padding-left: 10px;
    padding-right: 10px; }

.form-group.has-error span.has-error {
  color: #FF4444;
  font-size: 13px;
  display: block !important; }

.form-group.has-error .form-control-feedback {
  color: #FF4444; }

.form-group.has-warning span.has-warning {
  color: #fe974b;
  font-size: 13px;
  display: block !important; }

.form-group.has-warning .form-control-feedback {
  color: #fe974b; }

.form-group.has-success span.has-success {
  color: #4bcf99;
  font-size: 13px;
  display: block !important; }

.form-group.has-success .form-control-feedback {
  color: #4bcf99; }

/* Input groups */
.input-group {
  margin-bottom: 10px; }
  .input-group .form-control {
    padding-left: 5px; }
  .input-group .input-group-btn .btn {
    margin-bottom: 0; }
  .input-group span.input-group-addon {
    font-style: italic;
    border: none;
    border-radius: 0;
    border: none;
    background-color: #d7dde4;
    -webkit-transition: background-color ease-in-out 15s, color ease-in-out .15s;
    transition: background-color ease-in-out 15s, color ease-in-out .15s; }
    .input-group span.input-group-addon.focus {
      background-color: #85CE36;
      color: #ffffff; }

label,
.control-label {
  font-weight: 600; }

.form-control.underlined {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 1px solid #d7dde4; }
  .form-control.underlined.indented {
    padding: .375rem .75rem; }
  .has-error .form-control.underlined:focus,
  .has-warning .form-control.underlined:focus,
  .has-success .form-control.underlined:focus, .form-control.underlined:focus {
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom: 1px solid #85CE36; }
  .has-error .form-control.underlined {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #FF4444; }
  .has-warning .form-control.underlined {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #fe974b; }
  .has-success .form-control.underlined {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #4bcf99; }

.form-control.boxed {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .form-control.boxed:focus {
    border: 1px solid #85CE36; }

.radio,
.checkbox {
  display: none; }
  .radio + span,
  .checkbox + span {
    padding: 0;
    padding-right: 10px; }
  .radio + span:before,
  .checkbox + span:before {
    font-family: FontAwesome;
    font-size: 21px;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 10px;
    color: #c8d0da; }
  .radio:checked + span:before,
  .checkbox:checked + span:before {
    color: #85CE36; }
  .radio:disabled + span:before,
  .checkbox:disabled + span:before {
    opacity: 0.5;
    cursor: not-allowed; }
  .radio:checked:disabled + span:before,
  .checkbox:checked:disabled + span:before {
    color: #c8d0da; }

.checkbox + span:before {
  content: "\f0c8"; }

.checkbox:checked + span:before {
  content: "\f14a"; }

.checkbox.rounded + span:before {
  content: "\f111"; }

.checkbox.rounded:checked + span:before {
  content: "\f058"; }

.radio + span:before {
  content: "\f111"; }

.radio:checked + span:before {
  content: "\f192"; }

.radio.squared + span:before {
  content: "\f0c8"; }

.radio.squared:checked + span:before {
  content: "\f14a"; }

.form-control::-webkit-input-placeholder {
  font-style: italic;
  color: #c8d0da; }

.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #d7dde4; }

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #d7dde4; }

.form-control:-ms-input-placeholder {
  font-style: italic;
  color: #d7dde4; }

.images-container::after {
  display: block;
  clear: both;
  content: ""; }

.images-container .image-container {
  float: left;
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 35px;
  position: relative;
  border: 1px solid #e6eaee;
  overflow: hidden; }
  .images-container .image-container.active {
    border-color: #85CE36; }
  .images-container .image-container:hover .controls {
    bottom: 0;
    opacity: 1; }

.images-container .controls {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: -35px;
  text-align: center;
  height: 35px;
  font-size: 24px;
  -webkit-transition: bottom 0.2s ease, opacity 0.2s ease;
  transition: bottom 0.2s ease, opacity 0.2s ease;
  background-color: #fff; }
  .images-container .controls::after {
    display: block;
    clear: both;
    content: ""; }
  .images-container .controls .control-btn {
    display: inline-block;
    color: #4f5f6f;
    cursor: pointer;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }
    .images-container .controls .control-btn:hover {
      opacity: 1; }
    .images-container .controls .control-btn.move {
      cursor: move; }
    .images-container .controls .control-btn.star {
      color: #FFB300; }
      .images-container .controls .control-btn.star i:before {
        content: "\f006"; }
      .images-container .controls .control-btn.star.active i:before {
        content: "\f005"; }
    .images-container .controls .control-btn.remove {
      color: #FF4444; }

.images-container .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 130px;
  height: 135px;
  line-height: 135px;
  text-align: center; }

.images-container .image-container.main {
  border-color: #FFB300; }

.images-container .image-container.new {
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-style: dashed;
  border: 1px #85CE36 solid;
  color: #85CE36; }
  .images-container .image-container.new .image {
    font-size: 2.5rem; }
  .images-container .image-container.new:hover {
    opacity: 1; }

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.centralize-y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

/* *******************************************
*		  Item List general styles
********************************************** */
.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  line-height: 1.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .item-list {
      font-size: 1rem; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .item-list {
      font-size: 0.95rem; } }
  @media (max-width: 767.98px) {
    .item-list {
      font-size: 1.05rem; } }
  .item-list.striped > li {
    border-bottom: 1px solid #e9edf0; }
  .item-list.striped > li:nth-child(2n + 1) {
    background-color: #fcfcfd; }
    @media (max-width: 767.98px) {
      .item-list.striped > li:nth-child(2n + 1) {
        background-color: #f8f9fb; } }
  .item-list.striped .item-list-footer {
    border-bottom: none; }

/* *******************************************
*			Item general styles
********************************************** */
.item-list .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.item-list .item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-width: 100%; }
  .item-list .item-row.nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }

.item-list .item-col {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 10px 0;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
  -ms-flex-negative: 3;
      flex-shrink: 3;
  margin-left: auto;
  margin-right: auto;
  min-width: 0; }
  .item-list .item-col.fixed {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .item-list .item-col.pull-left {
    margin-right: auto; }
  .item-list .item-col.pull-right {
    margin-left: auto; }
  .item-list .item-col > div {
    width: 100%; }
  .item-list .item-col:last-child {
    padding-right: 0; }

.item-list .no-overflow {
  overflow: hidden; }

.item-list .no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* *******************************************
*				Item components
********************************************** */
.item-list {
  /********************************************
	*					Header
	*********************************************/
  /********************************************
	*					Heading
	*********************************************/
  /********************************************
	*					Checkbox
	*********************************************/
  /********************************************
	*					Image
	*********************************************/
  /********************************************
	*					Title
	*********************************************/
  /********************************************
	*					Stats
	*********************************************/
  /********************************************
	*				     Actions
	*********************************************/
  /********************************************
	*			   Something else?
	*********************************************/ }
  .item-list .item-list-header .item-col.item-col-header span {
    color: #999;
    font-size: 0.8rem;
    font-weight: 700 !important; }
  .item-list .item-heading {
    font-size: 0.9rem;
    display: none;
    color: #666;
    font-weight: 700;
    padding-right: 10px; }
    @media (max-width: 767.98px) {
      .item-list .item-heading {
        display: block; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .item-list .item-heading {
        width: 100%; } }
    @media (max-width: 575.98px) {
      .item-list .item-heading {
        width: 40%; } }
  .item-list .item-col.item-col-check {
    -ms-flex-preferred-size: 30px;
        flex-basis: 30px; }
    @media (max-width: 767.98px) {
      .item-list .item-col.item-col-check {
        -webkit-box-ordinal-group: -7;
            -ms-flex-order: -8;
                order: -8; } }
  .item-list .item-check {
    margin-bottom: 0; }
    .item-list .item-check .checkbox + span {
      padding-right: 0; }
      .item-list .item-check .checkbox + span:before {
        width: 20px; }
  .item-list .item-col.item-col-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 70px;
        flex-basis: 70px; }
    .item-list .item-col.item-col-img.xs {
      -ms-flex-preferred-size: 40px;
          flex-basis: 40px; }
    .item-list .item-col.item-col-img.sm {
      -ms-flex-preferred-size: 50px;
          flex-basis: 50px; }
    .item-list .item-col.item-col-img.lg {
      -ms-flex-preferred-size: 100px;
          flex-basis: 100px; }
    .item-list .item-col.item-col-img.xl {
      -ms-flex-preferred-size: 120px;
          flex-basis: 120px; }
    .item-list .item-col.item-col-img a {
      width: 100%; }
  .item-list .item-img {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-item-align: stretch;
        align-self: stretch;
    background-color: #efefef;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    .item-list .item-col.item-col-title {
      -webkit-box-ordinal-group: -3;
          -ms-flex-order: -4;
              order: -4; } }
  .item-list .item-col.item-col-title a {
    display: block; }
  .item-list .item-title {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: 600; }
  .item-list .item-stats {
    height: 1.4rem; }
  .item-list .item-col.item-col-actions-dropdown {
    -ms-flex-preferred-size: 40px;
        flex-basis: 40px;
    text-align: center;
    padding-left: 0 !important; }
    @media (max-width: 767.98px) {
      .item-list .item-col.item-col-actions-dropdown {
        -webkit-box-ordinal-group: -2;
            -ms-flex-order: -3;
                order: -3;
        -ms-flex-preferred-size: 40px !important;
            flex-basis: 40px !important;
        padding-right: 10px; } }
  .item-list .item-actions-dropdown {
    position: relative;
    font-size: 1.1rem; }
    .item-list .item-actions-dropdown.active .item-actions-block {
      max-width: 120px; }
    .item-list .item-actions-dropdown.active .item-actions-toggle-btn {
      color: #85CE36; }
      .item-list .item-actions-dropdown.active .item-actions-toggle-btn .active {
        display: block; }
      .item-list .item-actions-dropdown.active .item-actions-toggle-btn .inactive {
        display: none; }
    .item-list .item-actions-dropdown .item-actions-toggle-btn {
      color: #9ba8b5;
      font-size: 1.2rem;
      cursor: pointer;
      width: 100%;
      line-height: 30px;
      text-align: center;
      text-decoration: none; }
      .item-list .item-actions-dropdown .item-actions-toggle-btn .active {
        display: none; }
    .item-list .item-actions-dropdown .item-actions-block {
      height: 30px;
      max-width: 0px;
      line-height: 30px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 100%;
      background-color: #d7dde4;
      border-radius: 3px;
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out; }
      .item-list .item-actions-dropdown .item-actions-block.direction-right {
        right: auto;
        left: 100%; }
      .item-list .item-actions-dropdown .item-actions-block .item-actions-list {
        padding: 0;
        list-style: none;
        white-space: nowrap;
        padding: 0 5px; }
        .item-list .item-actions-dropdown .item-actions-block .item-actions-list li {
          display: inline-block;
          padding: 0; }
        .item-list .item-actions-dropdown .item-actions-block .item-actions-list a {
          display: block;
          padding: 0 5px; }
          .item-list .item-actions-dropdown .item-actions-block .item-actions-list a.edit {
            color: #38424C; }
          .item-list .item-actions-dropdown .item-actions-block .item-actions-list a.check {
            color: #40B726; }
          .item-list .item-actions-dropdown .item-actions-block .item-actions-list a.remove {
            color: #DB0E1E; }

/* *******************************************
*				Adjustments
********************************************** */
.card > .item-list .item > .item-row {
  padding: 0 15px; }
  @media (min-width: 1200px) {
    .card > .item-list .item > .item-row {
      padding: 0 20px; } }
  @media (max-width: 767.98px) {
    .card > .item-list .item > .item-row {
      padding: 0 10px; } }

.logo {
  display: inline-block;
  width: 45px;
  height: 25px;
  vertical-align: middle;
  margin-right: 5px;
  position: relative; }
  .logo .l {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #85CE36;
    position: absolute; }
    .logo .l.l1 {
      bottom: 0;
      left: 0; }
    .logo .l.l2 {
      width: 7px;
      height: 7px;
      bottom: 13px;
      left: 10px; }
    .logo .l.l3 {
      width: 7px;
      height: 7px;
      bottom: 4px;
      left: 17px; }
    .logo .l.l4 {
      bottom: 13px;
      left: 25px; }
    .logo .l.l5 {
      bottom: 0;
      left: 34px; }

.modal-body.modal-tab-container {
  padding: 0; }
  .modal-body.modal-tab-container .modal-tabs {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); }
    .modal-body.modal-tab-container .modal-tabs .nav-link {
      padding: 10px 20px;
      border: none; }
      .modal-body.modal-tab-container .modal-tabs .nav-link:hover, .modal-body.modal-tab-container .modal-tabs .nav-link.active {
        color: #85CE36;
        border-bottom: 2px solid #85CE36; }
      .modal-body.modal-tab-container .modal-tabs .nav-link.active {
        font-weight: 600; }

a:not(.btn) {
  -webkit-transition: initial;
  transition: initial;
  text-decoration: underline;
  color: #969696; }
  a:not(.btn):hover {
    text-decoration: underline;
    color: #70b02b; }
    a:not(.btn):hover:before {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
  a:not(.btn):focus {
    text-decoration: none; }

span a {
  vertical-align: text-bottom; }

[class^='nav'] li > a,
[class*=' nav'] li > a {
  display: block; }
  [class^='nav'] li > a:before,
  [class*=' nav'] li > a:before {
    display: none; }

.nav.nav-tabs-bordered {
  border-color: #85CE36; }
  .nav.nav-tabs-bordered + .tab-content {
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #85CE36;
    padding: 10px 20px 0; }
  .nav.nav-tabs-bordered .nav-item .nav-link {
    text-decoration: none; }
    .nav.nav-tabs-bordered .nav-item .nav-link:hover {
      color: #ffffff;
      background-color: #85CE36;
      border: 1px solid #85CE36; }
    .nav.nav-tabs-bordered .nav-item .nav-link.active {
      border-color: #85CE36;
      border-bottom-color: transparent; }
      .nav.nav-tabs-bordered .nav-item .nav-link.active:hover {
        background-color: #ffffff;
        color: inherit; }

.nav.nav-pills + .tab-content {
  border: 0;
  padding: 5px; }

.nav.nav-pills .nav-item .nav-link {
  text-decoration: none; }
  .nav.nav-pills .nav-item .nav-link:hover {
    color: #4f5f6f;
    background-color: transparent;
    border: 0; }
  .nav.nav-pills .nav-item .nav-link.active {
    border-color: #85CE36;
    border-bottom-color: transparent;
    background-color: #85CE36; }
    .nav.nav-pills .nav-item .nav-link.active:hover {
      background-color: #85CE36;
      color: #ffffff; }

#nprogress .bar {
  background: #85CE36 !important; }
  #nprogress .bar .peg {
    -webkit-box-shadow: 0 0 10px #85CE36, 0 0 5px #85CE36;
            box-shadow: 0 0 10px #85CE36, 0 0 5px #85CE36; }

#nprogress .spinner {
  top: 25px !important;
  right: 23px !important; }
  #nprogress .spinner .spinner-icon {
    border-top-color: #85CE36 !important;
    border-left-color: #85CE36 !important; }

.pagination {
  margin-top: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  .pagination .page-item .page-link {
    color: #85CE36; }
  .pagination .page-item.active .page-link,
  .pagination .page-item.active .page-link:focus,
  .pagination .page-item.active .page-link:hover {
    color: #fff;
    border-color: #85CE36;
    background-color: #85CE36; }

.sameheight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.table label {
  margin-bottom: 0; }

.table .checkbox + span {
  margin-bottom: 0; }
  .table .checkbox + span:before {
    line-height: 20px; }

/* Let's get this party started */
::-webkit-scrollbar {
  width: 7px;
  height: 7px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0; }

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #78bd2e; }

::-webkit-scrollbar-thumb:window-inactive {
  background: #85CE36; }

.error-card {
  width: 410px;
  min-height: 330px;
  margin: 60px auto; }
  .error-card .error-title {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-align: center;
    text-shadow: rgba(61, 61, 61, 0.3) 0.5px 0.5px, rgba(61, 61, 61, 0.2) 1px 1px, rgba(61, 61, 61, 0.3) 1.5px 1.5px; }
  .error-card .error-sub-title {
    font-weight: 100;
    text-align: center; }
  .error-card .error-container {
    text-align: center;
    visibility: hidden; }
    .error-card .error-container.visible {
      visibility: visible; }
  .error-card.global {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    margin: 0; }
    .error-card.global .error-title {
      color: #ffffff; }
    .error-card.global .error-sub-title,
    .error-card.global .error-container {
      color: #ffffff; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .error-card {
    width: 50%; }
    .error-card.global {
      position: relative;
      top: 25%;
      left: 0;
      -webkit-transform: inherit;
              transform: inherit;
      margin: 40px auto; } }

@media (max-width: 767.98px) {
  .error-card {
    width: 90%; }
    .error-card.global {
      position: relative;
      top: 25%;
      left: 0;
      -webkit-transform: inherit;
              transform: inherit;
      margin: 40px auto; } }

.wyswyg {
  border: 1px solid #d7dde4; }
  .wyswyg .ql-container {
    border-top: 1px solid #d7dde4; }
  .wyswyg .toolbar .btn {
    margin: 0; }
  .wyswyg .ql-container {
    font-size: 1rem; }
    .wyswyg .ql-container .ql-editor {
      min-height: 200px; }

.footer {
  background-color: #fff;
  position: absolute;
  left: 240px;
  right: 0;
  bottom: 0;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .footer-fixed .footer {
    position: fixed; }
  .footer .footer-block {
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px; }
  .footer .footer-github-btn {
    vertical-align: middle; }
  @media (max-width: 991.98px) {
    .footer {
      left: 0; } }
  .footer .author > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .footer .author > ul li {
      display: inline-block; }
      .footer .author > ul li:after {
        content: "|"; }
      .footer .author > ul li:last-child:after {
        content: ""; }
      @media (max-width: 991.98px) {
        .footer .author > ul li {
          display: block;
          text-align: right; }
          .footer .author > ul li:after {
            content: ""; } }
    @media (max-width: 991.98px) {
      .footer .author > ul {
        display: block; } }
    @media (max-width: 767.98px) {
      .footer .author > ul {
        display: none; } }

.modal .modal-content {
  border-radius: 0; }

.modal .modal-header {
  background-color: #85CE36;
  color: #ffffff; }

.modal .modal-footer .btn {
  margin-bottom: 0; }

.header {
  background-color: #d7dde4;
  height: 70px;
  position: absolute;
  left: 240px;
  right: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media (max-width: 991.98px) {
    .header {
      left: 0; } }
  @media (max-width: 767.98px) {
    .header {
      left: 0;
      height: 50px; } }
  .header-fixed .header {
    position: fixed; }
  @media (max-width: 767.98px) {
    .header .header-block {
      padding: 5px; } }

.sidebar {
  background-color: #3a4651;
  width: 240px;
  padding-bottom: 60px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 20; }
  @media (max-width: 991.98px) {
    .sidebar {
      position: fixed;
      left: -240px; } }
  .sidebar-fixed .sidebar {
    position: fixed; }
  .sidebar-open .sidebar {
    left: 0; }
  .sidebar .sidebar-container {
    position: absolute;
    top: 0;
    bottom: 51px;
    width: 100%;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden; }
    .sidebar .sidebar-container::-webkit-scrollbar-track {
      background-color: #2c353e; }
  .sidebar .label {
    font-size: .75em;
    display: inline-block;
    border-radius: .2em;
    padding: 2px 5px;
    background-color: #ccc;
    color: #000;
    margin: 0 3px; }
  .sidebar .label-screenful {
    color: #fff;
    background-color: #6769d4; }
  .sidebar .sidebar-menu {
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0; }
    .sidebar .sidebar-menu li a:focus,
    .open .sidebar .sidebar-menu li a:focus {
      background-color: inherit; }
    .sidebar .sidebar-menu ul {
      padding: 0;
      height: 0px;
      overflow: hidden; }
      .loaded .sidebar .sidebar-menu ul {
        height: auto; }
    .sidebar .sidebar-menu li.active ul {
      height: auto; }
    .sidebar .sidebar-menu li a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
      display: block; }
    .sidebar .sidebar-menu li a:hover,
    .sidebar .sidebar-menu li.open > a,
    .sidebar .sidebar-menu li.open a:hover {
      color: #ffffff;
      background-color: #2d363f; }
    .sidebar .sidebar-menu .arrow {
      float: right;
      font-size: 18px;
      line-height: initial;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      margin-right: 0; }
      .sidebar .sidebar-menu .arrow:before {
        content: "\f105" !important; }
    .sidebar .sidebar-menu li.open > a .arrow {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    .sidebar .sidebar-menu > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 15px; }
    .sidebar .sidebar-menu > li.active > a,
    .sidebar .sidebar-menu > li.active > a:hover {
      background-color: #85CE36 !important;
      color: #ffffff !important; }
    .sidebar .sidebar-menu > li.open > a {
      background-color: #333e48; }
    .sidebar .sidebar-menu > li > a i {
      margin-right: 5px;
      font-size: 16px; }
      .sidebar .sidebar-menu > li > a i.arrow {
        font-size: 20px; }
    .sidebar .sidebar-menu > li > .sidebar-nav > li a {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 50px;
      padding-right: 15px;
      background-color: #333e48; }
    .sidebar .sidebar-menu > li > .sidebar-nav > li.active a {
      color: #ffffff; }
    .sidebar .sidebar-menu > li > .sidebar-nav > li > .sidebar-nav > li a {
      padding-left: 60px;
      padding-right: 15px; }
    .sidebar .sidebar-menu > li > .sidebar-nav > li > .sidebar-nav > li > .sidebar-nav > li a {
      padding-left: 70px; }

.sidebar-overlay {
  position: absolute;
  display: none;
  left: 200vw;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  z-index: 15; }
  @media (max-width: 991.98px) {
    .sidebar-overlay {
      display: block; } }
  @media (max-width: 767.98px) {
    .sidebar-overlay {
      background-color: rgba(0, 0, 0, 0.7); } }
  @media (max-width: 991.98px) {
    .sidebar-open .sidebar-overlay {
      left: 0;
      opacity: 1; } }

.sidebar-mobile-menu-handle {
  display: none;
  position: absolute;
  top: 50px;
  background-color: transparent;
  width: 30px;
  bottom: 0;
  z-index: 10; }
  @media (max-width: 991.98px) {
    .sidebar-mobile-menu-handle {
      display: block; } }

.flot-chart {
  display: block;
  height: 225px; }
  .flot-chart .flot-chart-content {
    width: 100%;
    height: 100%; }
  .flot-chart .flot-chart-pie-content {
    width: 225px;
    height: 225px;
    margin: auto; }

.dashboard-page #dashboard-downloads-chart,
.dashboard-page #dashboard-visits-chart {
  height: 220px; }

@media (max-width: 575.98px) {
  .dashboard-page .items .card-header {
    border: none;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .dashboard-page .items .card-header .header-block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 100%;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-bottom: 1px solid #e9edf0; } }

.dashboard-page .items .card-header .title {
  padding-right: 0px;
  margin-right: 5px; }

.dashboard-page .items .card-header .search {
  margin: 0;
  vertical-align: middle;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 575.98px) {
    .dashboard-page .items .card-header .search {
      min-width: 50%; } }
  .dashboard-page .items .card-header .search .search-input {
    border: none;
    background-color: inherit;
    color: #C2CCD6;
    width: 100px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .dashboard-page .items .card-header .search .search-input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #C2CCD6; }
    .dashboard-page .items .card-header .search .search-input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #C2CCD6; }
    .dashboard-page .items .card-header .search .search-input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #C2CCD6; }
    .dashboard-page .items .card-header .search .search-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #C2CCD6; }
    @media (max-width: 575.98px) {
      .dashboard-page .items .card-header .search .search-input {
        min-width: 130px; } }
    .dashboard-page .items .card-header .search .search-input:focus {
      color: #7e8e9f; }
      .dashboard-page .items .card-header .search .search-input:focus::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #aab4c0; }
      .dashboard-page .items .card-header .search .search-input:focus:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #aab4c0; }
      .dashboard-page .items .card-header .search .search-input:focus::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #aab4c0; }
      .dashboard-page .items .card-header .search .search-input:focus:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #aab4c0; }
      .dashboard-page .items .card-header .search .search-input:focus + .search-icon {
        color: #7e8e9f; }
  .dashboard-page .items .card-header .search .search-icon {
    color: #C2CCD6;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    padding-right: 6px; }

.dashboard-page .items .card-header .pagination {
  display: inline-block;
  margin: 0; }

.dashboard-page .items .item-list .item-col-title {
  -webkit-box-flex: 9;
      -ms-flex-positive: 9;
          flex-grow: 9; }

.dashboard-page .items .item-list .item-col-date {
  text-align: right; }

@media (min-width: 1200px) {
  .dashboard-page .items .item-list .item-col-date {
    -webkit-box-flex: 4;
        -ms-flex-positive: 4;
            flex-grow: 4; } }

@media (max-width: 767.98px) {
  .dashboard-page .items .item-list .item-list-header {
    display: none; }
  .dashboard-page .items .item-list .item-row {
    padding: 0; }
  .dashboard-page .items .item-list .item-col {
    padding-left: 10px;
    padding-right: 10px; }
  .dashboard-page .items .item-list .item-col-img {
    padding-left: 10px;
    -ms-flex-preferred-size: 60px;
        flex-basis: 60px;
    padding-right: 0; }
  .dashboard-page .items .item-list .item-col-stats {
    text-align: center; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .dashboard-page .items .item-list .item-col-title {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-bottom: 1px solid #e9edf0; }
  .dashboard-page .items .item-list .item-col:not(.item-col-title):not(.item-col-img) {
    position: relative;
    padding-top: 35px; }
  .dashboard-page .items .item-list .item-heading {
    position: absolute;
    height: 30px;
    width: 100%;
    left: 0;
    top: 5px;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px; } }

@media (max-width: 575.98px) {
  .dashboard-page .items .item-list .item-col {
    border-bottom: 1px solid #e9edf0; }
  .dashboard-page .items .item-list .item-col-img {
    -ms-flex-preferred-size: 50px;
        flex-basis: 50px;
    -webkit-box-ordinal-group: -4;
        -ms-flex-order: -5;
            order: -5; }
  .dashboard-page .items .item-list .item-col-title {
    -ms-flex-preferred-size: calc(100% - 50px);
        flex-basis: calc(100% - 50px); }
  .dashboard-page .items .item-list .item-col:not(.item-col-title):not(.item-col-img) {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    text-align: left; }
    .dashboard-page .items .item-list .item-col:not(.item-col-title):not(.item-col-img) .item-heading {
      text-align: left; }
  .dashboard-page .items .item-list .item-col-date {
    border: none; } }

.dashboard-page .stats .card-block {
  padding-bottom: 0; }

.dashboard-page .stats .stat-col {
  margin-bottom: 20px;
  float: left;
  white-space: nowrap;
  overflow: hidden; }

.dashboard-page .stats .stat-icon {
  color: #BDBDBD;
  display: inline-block;
  font-size: 26px;
  text-align: center;
  vertical-align: middle;
  width: 50px; }

.dashboard-page .stats .stat-chart {
  margin-right: 5px;
  vertical-align: middle; }
  @media (min-width: 1200px) {
    .dashboard-page .stats .stat-chart {
      margin-right: 0.6vw; } }

.dashboard-page .stats .stat {
  vertical-align: middle; }
  @media (min-width: 1200px) {
    .dashboard-page .stats .stat .value {
      font-size: 1.3vw; } }
  @media (min-width: 1200px) {
    .dashboard-page .stats .stat .name {
      font-size: 0.9vw; } }

.dashboard-page .stats .stat-progress {
  height: 2px;
  margin: 5px 0;
  color: #85CE36;
  background-color: #dddddd; }
  .dashboard-page .stats .stat-progress .progress-bar {
    background-color: #85CE36; }

.dashboard-page #dashboard-sales-map .jqvmap-zoomin,
.dashboard-page #dashboard-sales-map .jqvmap-zoomout {
  background-color: #85CE36;
  height: 20px;
  width: 20px;
  line-height: 14px; }

.dashboard-page #dashboard-sales-map .jqvmap-zoomout {
  top: 32px; }

.dashboard-page .sales-breakdown .dashboard-sales-breakdown-chart {
  margin: 0 auto;
  max-width: 250px;
  max-height: 250px; }

.dashboard-page .tasks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: stretch;
      align-content: stretch; }
  .dashboard-page .tasks .title-block .title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .dashboard-page .tasks label {
    width: 100%;
    margin-bottom: 0; }
    .dashboard-page .tasks label .checkbox:checked + span {
      text-decoration: line-through; }
    .dashboard-page .tasks label span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
  .dashboard-page .tasks .tasks-block {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    margin-right: -5px; }
  .dashboard-page .tasks .item-list .item-col {
    padding-top: 5px;
    padding-bottom: 5px; }

.items-list-page .title-search-block {
  position: relative; }

@media (max-width: 767.98px) {
  .items-list-page .title-block {
    padding-bottom: 10px;
    margin-bottom: 13px; } }

.items-list-page .title-block .action {
  display: inline; }
  .items-list-page .title-block .action a {
    padding: 10px 15px; }
    .items-list-page .title-block .action a .icon {
      margin-right: 5px;
      text-align: center;
      width: 16px; }
  @media (max-width: 767.98px) {
    .items-list-page .title-block .action {
      display: none; } }

.items-list-page .items-search {
  position: absolute;
  margin-bottom: 15px;
  right: 0;
  top: 0; }
  @media (max-width: 767.98px) {
    .items-list-page .items-search {
      position: static; } }
  .items-list-page .items-search .search-button {
    margin: 0; }

.items-list-page .item-list .item-col.item-col-check {
  text-align: left; }

.items-list-page .item-list .item-col.item-col-img {
  text-align: left;
  width: auto;
  text-align: center;
  -ms-flex-preferred-size: 70px;
      flex-basis: 70px; }
  @media (min-width: 576px) {
    .items-list-page .item-list .item-col.item-col-img:not(.item-col-header) {
      height: 80px; } }

.items-list-page .item-list .item-col.item-col-title {
  text-align: left;
  margin-left: 0 !important;
  margin-right: auto;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 9;
      -ms-flex-positive: 9;
          flex-grow: 9; }

.items-list-page .item-list .item-col.item-col-sales {
  text-align: right;
  font-weight: 600; }

.items-list-page .item-list .item-col.item-col-stats {
  text-align: center; }

.items-list-page .item-list .item-col.item-col-category {
  text-align: left;
  font-weight: 600; }

.items-list-page .item-list .item-col.item-col-author {
  text-align: left;
  -webkit-box-flex: 4.5;
      -ms-flex-positive: 4.5;
          flex-grow: 4.5; }

.items-list-page .item-list .item-col.item-col-date {
  text-align: right; }

@media (max-width: 767.98px) {
  .items-list-page .card.items {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .items-list-page .item-list .item {
    border: none;
    margin-bottom: 10px;
    background-color: #fff;
    -webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
            box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
  .items-list-page .item-list .item-row {
    padding: 0 !important; }
  .items-list-page .item-list .item-col.item-col-author {
    -webkit-box-flex: 3;
        -ms-flex-positive: 3;
            flex-grow: 3; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .items-list-page .item-list .item-list-header {
    display: none; }
  .items-list-page .item-list .item {
    background-color: #fff;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
            box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
  .items-list-page .item-list .item-row {
    padding: 0; }
  .items-list-page .item-list .item-heading {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    line-height: 40px;
    padding-left: 0; }
  .items-list-page .item-list .item-col.item-col-check, .items-list-page .item-list .item-col.item-col-title, .items-list-page .item-list .item-col.item-col-actions-dropdown {
    border-bottom: 1px solid #d7dde4; }
    .items-list-page .item-list .item-col.item-col-check .item-heading, .items-list-page .item-list .item-col.item-col-title .item-heading, .items-list-page .item-list .item-col.item-col-actions-dropdown .item-heading {
      display: none; }
  .items-list-page .item-list .item-col.item-col-sales, .items-list-page .item-list .item-col.item-col-stats, .items-list-page .item-list .item-col.item-col-category, .items-list-page .item-list .item-col.item-col-author, .items-list-page .item-list .item-col.item-col-date {
    padding-top: 40px;
    position: relative; }
  .items-list-page .item-list .item-col.item-col-check {
    display: none; }
  .items-list-page .item-list .item-col.item-col-title {
    padding-left: 10px;
    text-align: left;
    margin-left: 0 !important;
    margin-right: auto;
    -ms-flex-preferred-size: calc(100% - 40px);
        flex-basis: calc(100% - 40px); }
  .items-list-page .item-list .item-col.item-col-img {
    padding-left: 10px;
    -ms-flex-preferred-size: 79px;
        flex-basis: 79px; }
  .items-list-page .item-list .item-col.item-col-sales {
    text-align: left; }
  .items-list-page .item-list .item-col.item-col-stats {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-category {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-author {
    text-align: center; }
  .items-list-page .item-list .item-col.item-col-date {
    padding-right: 10px;
    text-align: right;
    white-space: nowrap;
    -ms-flex-preferred-size: 100px;
        flex-basis: 100px;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 3;
        -ms-flex-positive: 3;
            flex-grow: 3; } }

@media (max-width: 575.98px) {
  .items-list-page .item-list-header {
    display: none; }
  .items-list-page .item-list .item {
    border: none;
    font-size: 0.9rem;
    margin-bottom: 10px;
    background-color: #fff;
    -webkit-box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1);
            box-shadow: 1px 1px 5px rgba(126, 142, 159, 0.1); }
    .items-list-page .item-list .item .item-col {
      text-align: right;
      border-bottom: 1px solid #d7dde4;
      padding-left: 10px; }
      .items-list-page .item-list .item .item-col[class^=item-col] {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%; }
      .items-list-page .item-list .item .item-col.item-col-check {
        display: none; }
      .items-list-page .item-list .item .item-col.item-col-img .item-img {
        padding-bottom: 65%; }
      .items-list-page .item-list .item .item-col.item-col-title {
        text-align: left;
        padding-bottom: 0;
        border: none;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-preferred-size: 0;
            flex-basis: 0; }
        .items-list-page .item-list .item .item-col.item-col-title .item-heading {
          display: none; }
        .items-list-page .item-list .item .item-col.item-col-title .item-title {
          font-size: 1rem;
          line-height: 1.4rem; }
      .items-list-page .item-list .item .item-col.item-col-actions-dropdown {
        border: none;
        padding-bottom: 0; }
      .items-list-page .item-list .item .item-col.item-col-sales {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-stats {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-category {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-author {
        text-align: left; }
      .items-list-page .item-list .item .item-col.item-col-date {
        text-align: left; } }

.screenful-page .section {
  text-align: center; }

.screenful-page .subtitle {
  margin-bottom: 30px; }

.screenful-page .description {
  margin-bottom: 50px; }

.screenful-page .screenful-logo {
  width: 300px;
  height: 160px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-image: url("../themes/icons/favicon.ico");
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 20px; }

.screenful-page .integrations-tools {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.screenful-page .integrations-title {
  margin-bottom: 40px; }

.screenful-page .integration-item {
  margin: 0 1%;
  margin-bottom: 30px; }

.screenful-page .integration-logo {
  width: 190px;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 10px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease; }

.screenful-page .integration-logo:hover {
  border: 1px solid #6769d4; }

.screenful-page .benefits-title {
  margin-bottom: 70px; }

.screenful-page .screen-colum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.screenful-page .screen-item {
  max-width: 500px; }

.screenful-page .ex-pic {
  width: 100%;
  border-radius: 10px;
  background-size: cover; }

.screenful-page .title {
  margin-bottom: 20px; }

.screenful-page a {
  text-decoration: none; }

.screenful-page ul {
  display: inline-block;
  text-align: left;
  width: 100%; }

.screenful-page .divider {
  background-color: #fff;
  padding-bottom: 1px;
  margin-bottom: 50px; }

.table-flip-scroll table {
  width: 100%; }

@media only screen and (max-width: 800px) {
  .table-flip-scroll .flip-content:after,
  .table-flip-scroll .flip-header:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }
  .table-flip-scroll html .flip-content,
  .table-flip-scroll html .flip-header {
    -ms-zoom: 1;
    zoom: 1; }
  .table-flip-scroll *:first-child + html .flip-content,
  .table-flip-scroll *:first-child + html .flip-header {
    -ms-zoom: 1;
    zoom: 1; }
  .table-flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative; }
  .table-flip-scroll th,
  .table-flip-scroll td {
    margin: 0;
    vertical-align: top; }
  .table-flip-scroll th:last-child,
  .table-flip-scroll td:last-child {
    border-bottom: 1px solid #ddd; }
  .table-flip-scroll th {
    border: 0 !important;
    border-right: 1px solid #ddd !important;
    width: auto !important;
    display: block;
    text-align: right; }
  .table-flip-scroll td {
    display: block;
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important; }
  .table-flip-scroll thead {
    display: block;
    float: left; }
  .table-flip-scroll thead tr {
    display: block; }
  .table-flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap; }
  .table-flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
    border-left: 1px solid #ddd; } }

#modal-media .modal-body {
  min-height: 250px; }

#modal-media .modal-tab-content {
  min-height: 300px; }

#modal-media .images-container {
  padding: 15px;
  text-align: center; }
  #modal-media .images-container .image-container {
    margin: 0 auto 10px auto;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: inline-block;
    float: none; }
    #modal-media .images-container .image-container:hover {
      border-color: rgba(133, 206, 54, 0.5); }
    #modal-media .images-container .image-container.active {
      border-color: rgba(133, 206, 54, 0.5); }

#modal-media .upload-container {
  padding: 15px; }
  #modal-media .upload-container .dropzone {
    position: relative;
    border: 2px dashed #85CE36;
    height: 270px; }
    #modal-media .upload-container .dropzone .dz-message-block {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateY(-50%) translateX(-50%);
              transform: translateY(-50%) translateX(-50%); }
      #modal-media .upload-container .dropzone .dz-message-block .dz-message {
        margin: 0;
        font-size: 24px;
        color: #85CE36;
        width: 230px; }

.header .header-block-buttons {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap; }
  .header .header-block-buttons .btn.header-btn {
    background-color: transparent;
    border: 1px solid #64798d;
    color: #64798d;
    margin: 0 5px;
    border-radius: 0.2rem; }
    .header .header-block-buttons .btn.header-btn:hover, .header .header-block-buttons .btn.header-btn:focus {
      border: 1px solid #3a4651;
      color: #3a4651; }
    @media (max-width: 767.98px) {
      .header .header-block-buttons .btn.header-btn span {
        display: none; } }

.header .header-block-collapse {
  padding-right: 5px; }
  @media (max-width: 767.98px) {
    .header .header-block-collapse {
      width: 155px; } }
  .header .header-block-collapse .collapse-btn {
    background: none;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #85CE36;
    font-size: 24px;
    line-height: 40px;
    border-radius: 0;
    outline: none;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: initial; }

.header .header-block-nav {
  margin-left: auto;
  white-space: nowrap;
  padding-right: 15px; }
  @media (max-width: 767.98px) {
    .header .header-block-nav {
      padding-right: 25px; } }
  .header .header-block-nav::after {
    display: block;
    clear: both;
    content: ""; }
  .header .header-block-nav a {
    text-decoration: none; }
  .header .header-block-nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .header .header-block-nav > ul {
    display: table; }
  .header .header-block-nav > ul > li {
    display: table-cell;
    position: relative; }
    .header .header-block-nav > ul > li:before {
      display: block;
      content: " ";
      width: 1px;
      height: 24px;
      top: 50%;
      margin-top: -12px;
      background-color: #8b9cb1;
      position: absolute;
      left: 0; }
    .header .header-block-nav > ul > li:first-child:before {
      display: none; }
  .header .header-block-nav > ul > li > a {
    padding: 0 15px;
    color: #4f5f6f; }
    .header .header-block-nav > ul > li > a:hover {
      color: #85CE36; }
  .header .header-block-nav .dropdown-menu {
    margin-top: 15px; }

.header .header-block-search {
  margin-right: auto;
  padding-left: 20px; }
  @media (max-width: 767.98px) {
    .header .header-block-search {
      padding-left: 10px;
      display: none; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .header .header-block-search {
      padding-left: 20px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .header .header-block-search {
      padding-left: 20px; } }
  @media (min-width: 1200px) {
    .header .header-block-search {
      padding-left: 35px; } }
  .header .header-block-search > form {
    float: right; }
    @media (max-width: 767.98px) {
      .header .header-block-search > form {
        padding-left: 0; } }
  .header .header-block-search .input-container {
    position: relative;
    color: #7e8e9f; }
    .header .header-block-search .input-container i {
      position: absolute;
      pointer-events: none;
      display: block;
      height: 40px;
      line-height: 40px;
      left: 0; }
    .header .header-block-search .input-container input {
      background-color: transparent;
      border: none;
      padding-left: 25px;
      height: 40px;
      max-width: 150px; }
      @media (max-width: 767.98px) {
        .header .header-block-search .input-container input {
          max-width: 140px; } }
    .header .header-block-search .input-container input:focus + .underline {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }

.customize {
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px 15px;
  text-align: center; }
  .customize .customize-header {
    margin-bottom: 10px; }

#customize-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 240px; }
  @media (max-width: 991.98px) {
    .sidebar-open #customize-menu {
      left: 0; } }
  @media (max-width: 991.98px) {
    #customize-menu {
      -webkit-transition: left 0.3s ease;
      transition: left 0.3s ease;
      left: -240px; } }
  #customize-menu > li > a {
    background-color: #3a4651;
    border-top: 1px solid rgba(45, 54, 63, 0.5); }
  #customize-menu > li > a:hover,
  #customize-menu > li.open > a {
    background-color: #2d363f; }
  #customize-menu .customize {
    width: 240px;
    color: rgba(255, 255, 255, 0.5);
    background-color: #2d363f;
    text-align: center;
    padding: 10px 15px;
    border-top: 2px solid #85CE36; }
    #customize-menu .customize .customize-item {
      margin-bottom: 15px; }
      #customize-menu .customize .customize-item .customize-header {
        margin-bottom: 10px; }
      #customize-menu .customize .customize-item label {
        font-weight: 400; }
        #customize-menu .customize .customize-item label.title {
          font-size: 14px; }
      #customize-menu .customize .customize-item .radio + span {
        padding: 0;
        padding-left: 5px; }
      #customize-menu .customize .customize-item .radio + span:before {
        font-size: 17px;
        color: #546273;
        cursor: pointer; }
      #customize-menu .customize .customize-item .radio:checked + span:before {
        color: #85CE36; }
      #customize-menu .customize .customize-item .customize-colors {
        list-style: none; }
        #customize-menu .customize .customize-item .customize-colors li {
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px; }
          #customize-menu .customize .customize-item .customize-colors li .color-item {
            display: block;
            height: 20px;
            width: 20px;
            border: 1px solid;
            cursor: pointer; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-red {
              background-color: #FB494D;
              border-color: #FB494D; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-orange {
              background-color: #FE7A0E;
              border-color: #FE7A0E; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-green {
              background-color: #8CDE33;
              border-color: #8CDE33; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-seagreen {
              background-color: #4bcf99;
              border-color: #4bcf99; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-blue {
              background-color: #52BCD3;
              border-color: #52BCD3; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.color-purple {
              background-color: #7867A7;
              border-color: #7867A7; }
            #customize-menu .customize .customize-item .customize-colors li .color-item.active {
              position: relative;
              font-family: FontAwesome;
              font-size: 17px;
              line-height: 17px; }
              #customize-menu .customize .customize-item .customize-colors li .color-item.active:before {
                content: "\f00c";
                position: absolute;
                top: 0;
                left: 0;
                color: #fff; }

.sidebar-header .brand {
  color: #fff;
  text-align: left;
  padding-left: 25px;
  line-height: 70px;
  font-size: 16px; }
  @media (max-width: 767.98px) {
    .sidebar-header .brand {
      line-height: 50px;
      font-size: 16px; } }

.header .header-block-nav .notifications {
  font-size: 16px; }
  .header .header-block-nav .notifications a {
    padding-right: 10px; }
  .header .header-block-nav .notifications .counter {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: -3px;
    left: -2px; }
  .header .header-block-nav .notifications.new .counter {
    color: #85CE36;
    font-weight: bold; }
  @media (max-width: 767.98px) {
    .header .header-block-nav .notifications {
      position: static; } }

.header .header-block-nav .notifications-dropdown-menu {
  white-space: normal;
  left: auto;
  right: 0;
  min-width: 350px; }
  .header .header-block-nav .notifications-dropdown-menu:before {
    position: absolute;
    right: 20px;
    bottom: 100%;
    margin-right: -1px; }
  .header .header-block-nav .notifications-dropdown-menu:after {
    position: absolute;
    right: 20px;
    bottom: 100%; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .notification-item {
    border-bottom: 1px solid rgba(126, 142, 159, 0.1);
    padding: 5px; }
    .header .header-block-nav .notifications-dropdown-menu .notifications-container .notification-item:hover {
      background-color: #f5f5f5; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .img-col {
    display: table-cell;
    padding: 5px; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .body-col {
    padding: 5px;
    display: table-cell; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container .img {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    vertical-align: top;
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .header .header-block-nav .notifications-dropdown-menu .notifications-container p {
    color: #4f5f6f;
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    margin: 0;
    vertical-align: top; }
    .header .header-block-nav .notifications-dropdown-menu .notifications-container p .accent {
      font-weight: bold; }
  .header .header-block-nav .notifications-dropdown-menu footer {
    text-align: center; }
    .header .header-block-nav .notifications-dropdown-menu footer a {
      color: #212529;
      -webkit-transition: none;
      transition: none; }
      .header .header-block-nav .notifications-dropdown-menu footer a:hover {
        background-color: #f5f5f5;
        color: #85CE36; }
  @media (max-width: 767.98px) {
    .header .header-block-nav .notifications-dropdown-menu {
      min-width: 100px;
      width: 100%;
      margin-top: 5px; }
      .header .header-block-nav .notifications-dropdown-menu:before, .header .header-block-nav .notifications-dropdown-menu:after {
        right: 107px; } }

.header .header-block-nav .profile .img {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  background-color: #8b9cb1;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  vertical-align: middle; }

.header .header-block-nav .profile .name {
  display: inline-block;
  margin-right: 9px;
  font-weight: bold; }
  @media (max-width: 767.98px) {
    .header .header-block-nav .profile .name {
      display: none; } }

.header .header-block-nav .profile .arrow {
  color: #85CE36; }

.header .header-block-nav .profile-dropdown-menu {
  left: auto;
  right: 0;
  min-width: 180px;
  white-space: normal; }
  .header .header-block-nav .profile-dropdown-menu:before {
    position: absolute;
    right: 10px;
    bottom: 100%;
    margin-right: -1px; }
  .header .header-block-nav .profile-dropdown-menu:after {
    position: absolute;
    right: 10px;
    bottom: 100%; }
  .header .header-block-nav .profile-dropdown-menu a {
    padding: 10px 15px; }
    .header .header-block-nav .profile-dropdown-menu a .icon {
      color: #85CE36;
      text-align: center;
      width: 16px; }
    .header .header-block-nav .profile-dropdown-menu a span {
      display: inline-block;
      padding-left: 5px;
      text-align: left;
      color: #7e8e9f; }
  .header .header-block-nav .profile-dropdown-menu .profile-dropdown-menu-icon {
    padding: 0; }
  .header .header-block-nav .profile-dropdown-menu .profile-dropdown-menu-topic {
    color: #7e8e9f;
    padding: 0; }
  .header .header-block-nav .profile-dropdown-menu .dropdown-divider {
    margin: 0; }
  .header .header-block-nav .profile-dropdown-menu .logout {
    border-top: 1px solid rgba(126, 142, 159, 0.1); }
  @media (max-width: 767.98px) {
    .header .header-block-nav .profile-dropdown-menu {
      margin-top: 8px; } }
